import React from "react"
import { graphql } from "gatsby"

import { getSrc } from "gatsby-plugin-image"

import Layout from "../components/layout"

export const query = graphql`
  query CourseInBriefQuery {
    strapiCourseInBrief {
      title
      description
      hero {
        localFile {
          childImageSharp {
            gatsbyImageData(
              placeholder: BLURRED
              quality: 80
              breakpoints: [768, 1024, 1280]
              layout: FULL_WIDTH
            )
          }
        }
      }
      content
    }
  }
`

const CourseInBrief = ({ data }) => {
  const courseInBrief = data.strapiCourseInBrief

  function markupCourseInBriefContent() {
    return { __html: courseInBrief.content }
  }

  return (
    <Layout>
      <div id="hero" className="container max-width-adaptive-lg margin-top-sm">
        <section
          className="bg-cover bg-center bg-no-repeat"
          style={{
            backgroundImage: `url(${getSrc(
              courseInBrief.hero.localFile.childImageSharp.gatsbyImageData
            )})`,
          }}
        >
          <div className="padding-y-xxxl"></div>
        </section>
      </div>

      <div
        id="content"
        className="container max-width-adaptive-sm padding-top-xl padding-bottom-xxl position-relative z-index-1"
      >
        <article className="article text-component">
          <h1 className="text-xxxl">{courseInBrief.title}</h1>
          <p className="text-md color-contrast-medium">
            {courseInBrief.description}
          </p>
          <div dangerouslySetInnerHTML={markupCourseInBriefContent()} />
        </article>
      </div>
    </Layout>
  )
}

export default CourseInBrief
